<div class="signup-wrapper">
  <a routerLink="/home" class="logo">
    <img src="/assets/image/interface/home/logo.png"/>
  </a>
  <mat-card class="box">
    <mat-card-header>
      <mat-card-title>Register</mat-card-title>
    </mat-card-header>

    <form [formGroup]="signUpForm" (ngSubmit)="signUp()" class="example-form">

      <mat-card-content>
        <mat-form-field class="example-full-width">
          <input matInput placeholder="Private web address" type="text" 
            formControlName="private_web_address" autocomplete="off" required (keydown)="onKeydown('private_web_address')" (keypress)="onKeypress($event)">
          <mat-error *ngIf="privateWebAddressInput.invalid">{{privateWebAddressInputError}}</mat-error>
        </mat-form-field>
        <div *ngIf="duplicatedWebAdd" class="d-block invalid-feedback">{{privateWebAddressInputError}}</div>
        <mat-form-field class="example-full-width">
          <input matInput placeholder="Store Name" type="text" 
            formControlName="store_name" autocomplete="off" required (keydown)="onKeydown('store_name')">
          <mat-error *ngIf="storeNameInput.invalid">{{storeNameInputError}}</mat-error>
        </mat-form-field>        
        <div *ngIf="duplicatedStoreName" class="d-block invalid-feedback">{{storeNameInputError}}</div>
        <div class="row">
          <div class="col-md-6">
            <mat-form-field class="example-full-width">
              <input matInput placeholder="First Name" type="text" formControlName="first_name" autocomplete="off" required>
              <mat-error *ngIf="firstNameInput.invalid">{{firstNameInputError}}</mat-error>
            </mat-form-field>
          </div>
          <div class="col-md-6">
            <mat-form-field class="example-full-width">
              <input matInput placeholder="Last Name" type="text" formControlName="last_name" autocomplete="off">
            </mat-form-field>
          </div>
        </div>
        <mat-form-field class="example-full-width">
          <input matInput placeholder="Email" type="email" formControlName="email" autocomplete="off" 
            required (keydown)="onKeydown('email')" (keypress)="onKeypress($event)">
          <mat-error *ngIf="emailInput.invalid">{{emailInputError}}</mat-error>
        </mat-form-field>
        <div *ngIf="duplicatedEmail" class="d-block invalid-feedback">{{emailInputError}}</div>

        <mat-form-field class="example-full-width">
          <input matInput placeholder="Password" [type]="hide ? 'password' : 'text'" formControlName="password" autocomplete="password" required>
          <mat-icon matSuffix (click)="hide = !hide" class="cursor-pointer">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
          <mat-error *ngIf="passwordInput.invalid">{{passwordInputError}}</mat-error>
        </mat-form-field>

        <mat-form-field class="example-full-width">
          <label> Phone Number *</label>
          <input ng2TelInput matInput (ng2TelOutput)="getPhoneNumber($event)" (hasError)="hasPhoneNumberError($event)" type="tel"   formControlName="phone">
          <mat-error *ngIf="phoneNumberInput.invalid">{{phoneNumberInputError}}</mat-error>
        </mat-form-field >
        <div *ngIf="phoneNumberInput.valid && !isValidNumber" class="d-block invalid-feedback">{{phoneNumberInputError}}</div>

        <div class="row">
          <div class="col-md-6">
            <mat-form-field class="w-100">
              <mat-label>Country</mat-label>
              <mat-select formControlName="country" required>
                  <mat-option *ngFor="let country of countryList" [value]="country._id">
                    {{country.country_name}}
                  </mat-option>
              </mat-select>                                
              <mat-error *ngIf="countryInput.invalid">{{countryInputError}}</mat-error>
            </mat-form-field> 
          </div>
          <div class="col-md-6">
            <mat-form-field class="example-full-width">
              <mat-label>Trading Currency</mat-label>
              <mat-select formControlName="default_currency" required>
                <mat-option *ngFor="let currency of currencies" [value]="currency._id">
                    {{currency.symbol_native}} - {{currency.code}} ({{currency.name}})
                </mat-option>
              </mat-select>
              <mat-error *ngIf="currencyInput.invalid">{{currencyInputError}}</mat-error>
            </mat-form-field>
          </div>
        </div>
      </mat-card-content>

      <button mat-raised-button color="accent" class="btn-block" [disabled]="loading">
        <mat-spinner [diameter]="28" *ngIf="loading" class="d-inline-block"></mat-spinner> Register
      </button>

      <div fxLayout="row" fxLayoutAlign="end none">
        <a class="p-2" routerLink="/auth/sign-in" routerLinkActive="active-link">Already had account ?</a>
      </div>
    </form>
  </mat-card>
</div>
