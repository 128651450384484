 <div class="login-wrapper">
  <a routerLink="/home" class="logo">
    <img src="/assets/image/interface/home/logo.png"/>
  </a>
  <mat-card class="box">
    <mat-card-header>
      <mat-card-title>Forgot Password?</mat-card-title>
    </mat-card-header>    
    <form [formGroup]="form" (ngSubmit)="submit()" class="example-form">
      <mat-form-field class="example-full-width">
        <input matInput placeholder="Email" type="email" formControlName="email" required>
        <mat-error *ngIf="emailInput.invalid">
          {{emailInputError}}
        </mat-error>
      </mat-form-field>
      <button mat-raised-button type="submit" color="accent" class="btn-block">Submit</button>
    </form>

    <div class="mt-3">
      <span fxFlex></span>
      <a routerLink="/auth/sign-in" routerLinkActive="active-link">Sing in</a>
    </div>
  </mat-card>

</div>
