import { Component, Inject, OnInit, Optional} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { UtilService} from '@service/util.service';
import { ToastService } from '@service/toast.service';
import { Constants } from '@config/constant';
import { AuthService } from '@app/_services/auth.service';
import { StorePolicy } from 'src/app/_classes/store_policy.class';

@Component({
  selector: 'app-timesheet-dlg',
  templateUrl: './timesheet-dlg.component.html',
  styleUrls: ['./timesheet-dlg.component.scss']
})
export class TimesheetDlgComponent implements OnInit {
  form: FormGroup; 
  cur_user: any;
  timesheet: any;  
  mode:string;
  myDate: string;
  users = [];
  allData: any;
  selectedUser:string = '';

  constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) public data,
    private dialogRef: MatDialogRef<TimesheetDlgComponent>,
    private fb: FormBuilder,
    private utilService: UtilService,
    private toastService: ToastService,
    private authService: AuthService,
    public store_policy:StorePolicy,
  ) {  
    this.form = this.fb.group({
      user_id: ['', [Validators.required]],
      date:[new Date(), [Validators.required]]  
    })
    this.users = [{id:'', name:'Please select user'}];
    this.selectedUser = '';
    // this.utilService.get('auth/users', {}).subscribe(result => {
    //   this.allData = result.body;
    //   for(let a of this.allData) {
    //     this.users.push({
    //       id: a._id,
    //       name: a.first_name + ' ' + a.last_name
    //     })
    //   }
    // });
    
    this.authService.currentUser.subscribe(user => {
      this.cur_user = user;
      this.utilService.get('auth/users', {user_id: user._id}).subscribe(result => {
        this.users = result.body;
      })
    });        
    // if(!data.user_id) {
    //   this.mode = 'in';
    //   this.form = this.fb.group({
    //     user_id: ['', [Validators.required, Validators.min(1)]],
    //     date:[new Date(), [Validators.required]]        
    //   });    
    // } else {
    //   this.mode = 'out';
    //   this.form = this.fb.group({
    //     end_date:[new Date(), [Validators.required]]        
    //   });    
    // }
  }

  ngOnInit(): void {
    var d = new Date();
    var dd = d.getDate();
    var mm = d.getMonth() + 1;
    var yy = d.getFullYear();
    var hh = d.getHours();
    var ii = d.getMinutes();
    var ss = d.getSeconds();
    this.myDate = mm + '/' + dd + '/' + yy + ' ' + hh + ':' + ii + ':' + ss;
    this.mode = "In";
    this.form.get('user_id').setValue('');
  }
  
  userChange(value){
    console.log("bookconsole-------", value);
    this.utilService.get('auth/timesheet', {user_id: value}).subscribe(result => {
      this.allData = result.body;
      const filterdata = this.allData.filter(item => item.end_date === null);
      if (filterdata.length > 0) {
        // time out
        this.mode = "Out" ;
      } else {
        // time in
        this.mode = "In" ;
      }
    })
  }

  doAction(){    
    if(this.form.valid){     
      if(this.mode === "In") {
        const data = {
          user_id: this.selectedUser,
          start_date: this.form.get('date').value,
          end_date: null,
        };      
        this.utilService.post('auth/timesheet', data).subscribe(result => {
          this.toastService.showSuccessSave();
          this.dialogRef.close({action: 'process', user_id: data.user_id});
        })
      } else {
        const data = {
          user_id: this.selectedUser,          
          end_date: this.form.get('date').value
        };      
        this.utilService.put('auth/timesheet', data).subscribe(result => {
          this.toastService.showSuccessSave();
          this.dialogRef.close({action: 'process', user_id: data.user_id});
        })
      }
    }
  }


  public get user_name():string {
    let result = '';
    let index = this.users.findIndex(item => item._id == this.data.user_id);
    if(index > -1) {
      let user = this.users[index];
      result = user.first_name + ' ' + user.last_name;
    }
    return result;
  }

  get userIdInput(): any {return this.form.get('user_id'); }
  get userIdInputError(): string {    
    if (this.userIdInput.hasError('required')) { return Constants.message.requiredField; }
    if (this.userIdInput.hasError('min')) { return Constants.message.invalidMinValue.replace("?", "1"); }
  }

  get startDateInput(): any {return this.form.get('date'); }
  get startDateInputError(): string {    
    if (this.startDateInput.hasError('required')) { return Constants.message.requiredField; }
  }

}




