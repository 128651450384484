 <div class="login-wrapper">
  <a routerLink="/home" class="logo">
    <img src="/assets/image/interface/home/logo.png"/>
  </a>
  <mat-card class="box">
    <mat-card-header>
      <mat-card-title>Welcome to <span class="text-black">Resto</span>POS</mat-card-title>
    </mat-card-header>
    <ng-container *ngIf="invalidEmailOrPwd"><div class="invalid-feedback mt-3">Store Name, Email or Password Invalid</div></ng-container>
    <form [formGroup]="signInForm" (ngSubmit)="signIn()" class="example-form">
      <mat-card-content>
        <mat-form-field class="example-full-width">
          <input matInput placeholder="Private Web Address" type="text" formControlName="private_web_address" required>
          <mat-error *ngIf="storeNameInput.invalid">
            {{storeNameInputError}}
          </mat-error>
        </mat-form-field>

        <mat-form-field class="example-full-width">
          <input matInput placeholder="Email" type="email" formControlName="email" required>
          <mat-error *ngIf="emailInput.invalid">
            {{emailInputError}}
          </mat-error>
        </mat-form-field>

        <mat-form-field class="example-full-width">
          <input matInput placeholder="Password" [type]="hide ? 'password' : 'text'" formControlName="password" autocomplete="password" required>
          <mat-icon matSuffix (click)="hide = !hide" class="cursor-pointer">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
          <mat-error *ngIf="passwordInput.invalid">
            {{passwordInputError}}
          </mat-error>
        </mat-form-field>
      </mat-card-content>
      <button mat-raised-button type="submit" color="accent" class="btn-block">Log in</button>
    </form>

    <div class="mt-3">
      <span fxFlex></span>
      <a routerLink="/auth/sign-up" routerLinkActive="active-link">Don't have account ?</a>
    </div>
    <div class="mt-3">
      <span fxFlex></span>
      <a routerLink="/auth/forgot-password" routerLinkActive="active-link">Forgot Password ?</a>
    </div>
  </mat-card>

</div>
