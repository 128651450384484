<div class="p-3">
  <div class="d-flex justify-content-between align-items-center mb-3">
    <div class="h4">Time {{mode}}</div>
    <button mat-mini-fab [matDialogClose]="false">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <form [formGroup]="form" (ngSubmit)="doAction()">  
    <mat-form-field class="w-100">
      <mat-label>User</mat-label>
      <mat-select [(ngModel)]="selectedUser" formControlName="user_id" #choosedValue (valueChange)="userChange(choosedValue.value)" required >
        <mat-option *ngFor="let user of users" [value]="user._id">{{user.first_name}} {{user.last_name}}</mat-option>
      </mat-select>          
      <mat-error *ngIf="userIdInput.invalid">{{userIdInputError}}</mat-error>
    </mat-form-field>
    <mat-form-field class="w-100">
      <mat-label>Time {{mode}} Date Time</mat-label>
      <input matInput formControlName="date" [ngxMatDatetimePicker]="date" placeholder="Choose a date" (focus)="date.open()">
      <mat-datepicker-toggle matSuffix [for]="date"></mat-datepicker-toggle>        
      <ngx-mat-datetime-picker #date [showSeconds]="true"></ngx-mat-datetime-picker>
    </mat-form-field>
    <div class="d-flex justify-content-end align-items-right p-1">
      <button type="submit" mat-raised-button class="bg-custom-1 ml-1 mt-3 text-white" color="primary">Save</button>
    </div>
  </form>
</div>

