<div class="home-container">
  <div class="home-content">
    <mat-card class="p-3">      
      <mat-card-content>
        <div class="row" id="date_range">
          <div class="col-md-6">
            <mat-button-toggle-group [(ngModel)]="period" aria-label="Period" (change)="changePeriod()">
              <mat-button-toggle value="daily">Daily</mat-button-toggle>
              <mat-button-toggle value="monthly">Monthly</mat-button-toggle>
              <mat-button-toggle value="yearly">Yearly</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
          <div class="col-md-6 text-right">
            <mat-form-field appearance="outline">
              <mat-date-range-input [rangePicker]="picker">
                <input matStartDate [(ngModel)]="date_from" placeholder="From">
                <input matEndDate [(ngModel)]="date_to" placeholder="To" (dateChange)="changeDateRange()">
              </mat-date-range-input>
              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-date-range-picker #picker></mat-date-range-picker>
            </mat-form-field>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
    <mat-card class="mt-3 p-3">
      <mat-card-header>
        <mat-card-title>Sales Dashboard</mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <div class="row">
          <div class="col-md-2 pt-3 text-center">
            <div class="legend sales" [ngClass]="{'active':sales_mode=='sales'}" (click)="changeSalesMode('sales')">
              <div>Sales</div>
              <div class="h5">{{total_sales}} <small>Tax excl.</small></div>
            </div>
            <div class="legend orders" [ngClass]="{'active':sales_mode=='orders'}" (click)="changeSalesMode('orders')">
              <div>Orders</div>
              <div class="h5">{{total_orders}}</div>
            </div>
          </div>
          <div class="col-md-10">
            <div class="chart-wrapper mb-4">
              <canvas baseChart
                height="100"
                [datasets]="salesChartData"
                [labels]="lineChartLabels"
                [options]="lineChartOptions"
                [colors]="salesChartColors"
                [legend]="lineChartLegend"
                [chartType]="lineChartType"
                [plugins]="lineChartPlugins">
              </canvas>
            </div>
          </div>
        </div>
      </mat-card-content>
    </mat-card>

    <mat-card class="mt-3 p-3">
      <mat-card-header>
        <mat-card-title>Product Sale Dashboard</mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <div class="row">
          <div class="col-md-3 pt-3 text-center">
            <form [formGroup]="form">
              <mat-form-field class="w-100">
                <mat-label>Search Product</mat-label>
                <input matInput formControlName="selectedProduct" [matAutocomplete]="auto" placeholder="Search or scan to add a product">
                <mat-icon matPrefix>search</mat-icon>
              </mat-form-field>
              <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn" (optionSelected)='addProduct($event.option.value)'>
                  <mat-option *ngIf="isLoading" class="is-loading"><mat-spinner diameter="28"></mat-spinner></mat-option>
                  <ng-container *ngIf="!isLoading">
                  <mat-option *ngFor="let product of filteredProducts" [value]="product">
                      <span>{{ product.data.name }}</span>
                      <small *ngIf="product.data.variant_products.length>0"> ({{product.data.variant_products.length}} variants)</small>
                  </mat-option>
                  </ng-container>
              </mat-autocomplete>
            </form>
            <div class="p-2">
              <div class="d-flex justify-content-between mb-2 product-legend" *ngFor="let p of selectedProducts; let i=index;" [style]="getColor(i)">
                <div>{{p.data.name}}</div>
                <div><mat-icon class="cursor-pointer" (click)="removeProduct(i)">cancel</mat-icon></div>
              </div>
            </div>
          </div>
          <div class="col-md-9">
            <div class="d-flex mb-2">
              <div class="legend2 sales" [ngClass]="{'active':product_mode=='sales'}" (click)="changeProductMode('sales')">
                <div>Sales</div>
                <div class="h6">{{total_product_sales}} <small>Tax excl.</small></div>
              </div>
              <div class="legend2 orders" [ngClass]="{'active':product_mode=='orders'}" (click)="changeProductMode('orders')">
                <div>Solds</div>
                <div class="h6">{{total_product_orders}}</div>
              </div>
            </div>
            <div class="chart-wrapper mb-4">
              <canvas baseChart
                height="120"
                [datasets]="productChartData"
                [labels]="lineChartLabels"
                [options]="lineChartOptions"
                [colors]="productChartColors"
                [legend]="lineChartLegend"
                [chartType]="lineChartType"
                [plugins]="lineChartPlugins">
              </canvas>
            </div>
          </div>
        </div>
      </mat-card-content>
    </mat-card>

    <div class="row">
      <div class="col-lg-6">
        <mat-card class="p-1 mt-3">
          <mat-card-header>
            <mat-card-title><h2>Sales Report</h2></mat-card-title>
          </mat-card-header>
          <mat-card-content class="p-4 mb-2">
            <div class="d-flex justify-content-center flex-wrap">
              <div class="report-detail green-bg">
                <label>Total for this month</label>
                <div class="value">{{totalByMonth}}</div>
              </div>
              <div class="report-detail blue-bg">
                <label>Total for today</label>
                <div class="value">{{totalByDay}}</div>
              </div>            
            </div>
          </mat-card-content>
        </mat-card>

        <mat-card class="p-1 mt-3">
          <mat-card-header>
            <mat-card-title><h2>Stock Report</h2></mat-card-title>
          </mat-card-header>
          <mat-card-content class="p-4 mb-2">
            <div class="d-flex justify-content-center flex-wrap">
              <div class="report-detail yellow-bg">
                <label>Stock Levels</label>
                <div class="value">{{stockLevels}}</div>
              </div>
              <div class="report-detail red-bg">
                <label>Stock on Hand</label>
                <div class="value">{{stockOnHand}}</div>
              </div>
            </div>
          </mat-card-content>
        </mat-card>
      </div>

      <div class="col-lg-6">
        <mat-card class="p-1 mt-3">
          <mat-card-header>
            <mat-card-title><h2>Product Report</h2></mat-card-title>
          </mat-card-header>
          <mat-card-content class="p-4 mb-2">
            <div class="d-flex justify-content-center flex-wrap">
              <div class="report-detail weight-yellow-bg">
                <label>Total by User</label>
                <div class="value">{{productsByUser}}</div>
              </div>
              <div class="report-detail green-bg">
                <label>Total by Outlet</label>
                <div class="value">{{productsByOutlet}}</div>
              </div>
              <div class="report-detail light-red-bg">
                <label>Total by Customer</label>
                <div class="value">{{productsByCustomer}}</div>
              </div>            
            </div>            
          </mat-card-content>
        </mat-card>

        <mat-card class="p-1 mt-3">
          <mat-card-header>
            <mat-card-title><h2>Register Report</h2></mat-card-title>
          </mat-card-header>
          <mat-card-content class="p-4 mb-2">
            <div class="d-flex justify-content-center flex-wrap">
              <div class="report-detail weight-blue-bg">
                <label>Register Closures</label>
                <div class="value">{{registerClosures}}</div>
              </div>              
            </div>            
          </mat-card-content>
        </mat-card>

      </div>
    </div>
  </div>
</div>
